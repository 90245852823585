<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Coupon</h4>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <v-select
              v-model="formData.store_id"
              label="Store"
              item-text="name"
              item-value="id"
              :items="serverData.stores"
              clearable
              outlined
              :error-messages="store_idErrors"
              @input="$v.formData.store_id.$touch()"
              @blur="$v.formData.store_id.$touch()"
            ></v-select>
            <v-select
              v-model="formData.status"
              label="Status"
              item-text="name"
              item-value="id"
              :items="serverData.statuses"
              clearable
              outlined
              :error-messages="statusErrors"
              @input="$v.formData.status.$touch()"
              @blur="$v.formData.status.$touch()"
            ></v-select>
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              :error-messages="descriptionErrors"
              @input="$v.formData.description.$touch()"
              @blur="$v.formData.description.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.code"
              label="Code"
              clearable
              outlined
              :error-messages="codeErrors"
              @input="$v.formData.code.$touch()"
              @blur="$v.formData.code.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.discount"
              label="Discount"
              type="Number"
              clearable
              outlined
              @change="() => validateMinValue('discount', 0)"
              :error-messages="discountErrors"
              @input="$v.formData.discount.$touch()"
              @blur="$v.formData.discount.$touch()"
            ></v-text-field>

            <v-datetime-picker
              label="From"
              name="maa2"
              v-model="formData.from"
              prepend-icon="mdi-calendar"
              outlined
            >
            </v-datetime-picker>

            <v-datetime-picker
              label="To"
              name="maa"
              v-model="formData.to"
              prepend-icon="mdi-calendar"
              outlined
            >
            </v-datetime-picker>
          </form>

          <!--end::Body-->
          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      store_id: { required },
      code: { required },
      description: { required },
      discount: { required },
      // from: { required },
      // to: { required },
      status: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    menu2: false,
    formData: {
      coupon_id: null,
      store_id: null,
      code: null,
      description: null,
      discount: null,
      from: null,
      to: null,
      status: null,
    },
  }),
  beforeMount() {
    this.resetEditForm();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { coupon_id: this.$store.getters.getItemForAction.id };
      ApiService.post("/stores/portal/coupons/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.serverData.statuses = [
            { name: "Enable", id: 1 },
            { name: "Disable", id: 0 },
          ];
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    convertToFormData() {
      let data = { ...this.formData };
      data.to = this.dateFormatter(new Date(this.formData.to));
      data.from = this.dateFormatter(new Date(this.formData.from));

      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // console.log("not valid");
        return;
      } else {
        this.pageLoader(true);
        let data = this.convertToFormData();
        ApiService.post("/stores/portal/coupons/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Courier status data has been updated!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.coupon };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.coupon).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          coupon_id: null,
          store_id: null,
          code: null,
          description: null,
          discount: null,
          from: null,
          to: null,
          status: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    store_idErrors: function () {
      return this.handleFormValidation("store_id", this);
    },
    codeErrors: function () {
      return this.handleFormValidation("code", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
    discountErrors: function () {
      return this.handleFormValidation("discount", this);
    },
    // fromErrors: function() {
    //   return this.handleFormValidation("from", this);
    // },
    // toErrors: function() {
    //   return this.handleFormValidation("to", this);
    // },
    statusErrors: function () {
      return this.handleFormValidation("status", this);
    },
  },
};
</script>
