<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Coupon</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-select
            v-model="formData.store_id"
            label="Store"
            item-text="name"
            item-value="id"
            :items="serverData.stores"
            clearable
            outlined
            :error-messages="store_idErrors"
            @input="$v.formData.store_id.$touch()"
            @blur="$v.formData.store_id.$touch()"
          ></v-select>
          <v-select
            v-model="formData.status"
            label="Status"
            item-text="name"
            item-value="id"
            :items="serverData.statuses"
            clearable
            outlined
            :error-messages="statusErrors"
            @input="$v.formData.status.$touch()"
            @blur="$v.formData.status.$touch()"
          ></v-select>
          <v-text-field
            v-model="formData.description"
            label="Description"
            clearable
            outlined
            :error-messages="descriptionErrors"
            @input="$v.formData.description.$touch()"
            @blur="$v.formData.description.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="formData.code"
            label="Code"
            clearable
            outlined
            :error-messages="codeErrors"
            @input="$v.formData.code.$touch()"
            @blur="$v.formData.code.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="formData.discount"
            label="Discount"
            type="Number"
            clearable
            outlined
            @change="() => validateMinValue('discount', 0)"
            :error-messages="discountErrors"
            @input="$v.formData.discount.$touch()"
            @blur="$v.formData.discount.$touch()"
          ></v-text-field>
          <v-datetime-picker
            label="From"
            name="maa2"
            v-model="formData.from"
            prepend-icon="mdi-calendar"
            outlined
            dateFormat="yyyy-MM-dd"
            timeFormat="HH:mm"
          >
          </v-datetime-picker>
          <v-datetime-picker
            label="To"
            name="maa"
            v-model="formData.to"
            prepend-icon="mdi-calendar"
            outlined
            dateFormat="yyyy-MM-dd"
            timeFormat="HH:mm"
          >
          </v-datetime-picker>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
// import axios from "axios";
// import { getToken } from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "AddItem",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      store_id: { required },
      code: { required },
      description: { required },
      discount: { required },
      // from: { required },
      // to: { required },
      status: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    menu2: false,
    modal: false,
    date: new Date().toISOString().substr(0, 10),
    formData: {
      store_id: null,
      code: null,
      description: null,
      discount: null,
      from: null,
      to: null,
      status: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/stores/portal/coupons/create")
        .then((response) => {
          this.serverData = response.data;
          this.serverData.statuses = [
            { name: "Enable", id: 1 },
            { name: "Disable", id: 0 },
          ];
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if ((item[1] && item[0] != "image") || item[1] == 0) {
      //     data.append(item[0], item[1]);
      //   } else if (item[1] && item[0] == "image") {
      //     data.append(item[0], item[1][0]);
      //   }
      // });
      let data = { ...this.formData };
      // console.log(
      //   this.formData.to.toLocaleDateString(),
      //   "----",
      //   this.formData.to.toLocaleTimeString()
      // );

      data["to"] = this.dateFormatter(this.formData.to);

      data["from"] = this.dateFormatter(this.formData.from);

      // data["to"] =
      // this.formData.to
      //   .toLocaleDateString()
      //   .slice(0, 10)
      //   .split("/")
      //   .reverse()
      //   .join("-") +
      // " " +
      // this.formData.to.toLocaleTimeString("en-US", { hour12: false });
      // data["from"] =
      //   this.formData.from
      //     .toLocaleDateString()
      //     .slice(0, 10)
      //     .split("/")
      //     .reverse()
      //     .join("-") +
      //   " " +
      //   this.formData.from.toLocaleTimeString("en-US", { hour12: false });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // console.log("not valid", this.formData);
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        let data = this.convertToFormData();
        ApiService.post("/stores/portal/coupons/store", data)
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `Coupon successfully created!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.resetCreateForm();
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        store_id: null,
        code: null,
        description: null,
        discount: null,
        from: null,
        to: null,
        status: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    store_idErrors: function () {
      return this.handleFormValidation("store_id", this);
    },
    codeErrors: function () {
      return this.handleFormValidation("code", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
    discountErrors: function () {
      return this.handleFormValidation("discount", this);
    },
    // fromErrors: function() {
    //   return this.handleFormValidation("from", this);
    // },
    // toErrors: function() {
    //   return this.handleFormValidation("to", this);
    // },
    statusErrors: function () {
      return this.handleFormValidation("status", this);
    },
  },
};
</script>
